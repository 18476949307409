
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Wallet Points",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    DatePicker,
    
  },
  data() {
    return {
      title: "Add Wallet Points",
      items: [
        {
          text: "Wallet Points",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      excelUpload:false,
      dob:"",
      empEmailID:"",
      empName:"",
      empMobileNO:"",
      loading: false,
      otp:"",
      showOtp:false,
      showSubmitBtn:false,
      file: '',
      branches:[],
      branchID:"",
      cityArr:[],
      city:"",
      countryArr:[],
      country:"",
      walletExpiry : "",
      //excelDownloadUrl: 'http://127.0.0.1:8000/api/downloadUserListExcel?branchid='+sessionStorage.getItem('corpBranchID')+'&userid='+this.$storageData.profile.pid,

    };
  },

    validations: {
   
      empName: {
        required,
      },
      empEmailID: {
        required,
      },
    
  

  },
  methods:{

    submitCorporateUsers(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          let formData = new FormData();  
         
            formData.append('empEmailID', this.empEmailID);
            formData.append('empMobileNO', this.empMobileNO);
            formData.append('empName', this.empName);
            formData.append('dob', this.dob);
            formData.append('corpBranchID',sessionStorage.getItem('corpBranchID'));
            formData.append('loginTypeID',this.$storageData.login_type);
         
      this.axios.post(this.$loggedRole+"/addCorporateUsers",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;
//console.log(result);                                                                                                                                                                                                                                                
              setTimeout(() => {
                   this.showDismissibleAlert = false;
             },2000);

      })
     .catch((error)=> {
       this.loading = false;
                  // console.error(error.response);
                        alert(error.response.data.data);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                    });
      }  
    },
    sendOtp(){
     
    if (this.file.length <= 0) {
        alert("Please Choose file for upload");
        return;
    }
    
            this.loading = true;
            let formData = new FormData();  
            formData.append('corpBranchID',sessionStorage.getItem('corpBranchID'));
            formData.append('emailID',this.$storageData.email);
            formData.append('name',this.$storageData.name);
            this.axios.post(this.$loggedRole+"/sendOtpToCorporateAdmin",  formData)
            .then((result)=>{
                    this.loading = false;
                    this.status = result.data.status;
                    if(this.status==200){
                        this.showOtp = true;
                        this.showSubmitBtn = true;
                    }
            })
          .catch((error)=> {
            this.loading = false;
                              
              alert(error.response.data.data);
          });
    },
    submitExcelUsers(){
   
    if (this.file.length <= 0) {
        alert("Please Choose file for upload");
        return;
    }else{
          let formData = new FormData();  
         
            formData.append('file', this.file);
            formData.append('otp',this.otp);
            formData.append('corpBranchID',sessionStorage.getItem('corpBranchID'));
            formData.append('loginTypeID',this.$storageData.login_type);
            formData.append('createdBy',this.$storageData.profile.pid);
            let headers = authHeader();
            headers['Content-Type'] = 'multipart/form-data';
            this.axios.post(this.$loggedRole+"/importUserWalletPoints",  formData, {
                headers
              })
            .then((result)=>{
              this.status = result.data.data;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                    this.file = '';
                    this.otp = '';
                    this.showOtp=false,
                    this.showSubmitBtn=false,

                    setTimeout(() => {
                        this.showDismissibleAlert = false;
                    },2000);

            })
          .catch((error)=> {
            this.loading = false;
                              
                              alert(error.response.data.data);
                          });
            } 
    },
    getCorporateList(){
         this.axios.get(this.$loggedRole+"/getCorporateBranchList")
          .then((result)=>{
            this.corporates = result.data.data;
              
        });
    },

    handleFileUpload: function(event) {
    
      var input = event.target;
            if (input.files && input.files[0]) {
               this.file = input.files[0];
          }
               
   // console.log(this.file);
  },
  downloadSample(){
            this.axios.get('downloadUserListExcel', {
            responseType: 'blob',
            params: {
              branchid: sessionStorage.getItem('corpBranchID')
            }
           
          }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `employees.xlsx`
            )
            document.body.appendChild(link)
            link.click()
          })
        //  this.axios
        // .get(
        //   "downloadUserListExcel", {'corpBranchID':sessionStorage.getItem('corpBranchID')}, 
        //     // {
        //     //     headers:
        //     //     {
        //     //         'Content-Disposition': "attachment; filename=template.xlsx",
        //     //         'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //     //     },
        //     //     responseType: 'blob',
        //     // }
        // )
        // .then((response) => {
        //         console.log(response.data);
        //        var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/vnd.ms-excel' }));
        //         //console.log(fileURL);
        //         var fileLink = document.createElement('a');
        //         fileLink.href = fileURL;
        //         fileLink.setAttribute('download', 'test.xlsx');
        //         document.body.appendChild(fileLink);
        //         fileLink.click();
          
        //   //  const url = window.URL.createObjectURL(new Blob([response.data]));
        //   //       const link = document.createElement('a');
        //   //       link.href = url;
        //   //       link.setAttribute('download', 'template.xlsx');
        //   //       document.body.appendChild(link);
        //   //       link.click();

          
        // });
  },
   getCorporateDetailsByID(corpBranchID){
            this.axios
        .post(this.$loggedRole+"/getCorporateDetailsByID", {'corpBranchID':corpBranchID}
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.corpData = response.data.data.corpBranchName;
          
          
        });
    },
    getCorporateBranchByCorpID(){
         this.axios
        .post(this.$loggedRole+"/getCorporateBranchByCorpID", {corporateID:this.$storageData.profile.corporateID,city: (this.city) ? this.city.city : ""}
        )
        .then((response) => {
        this.branches = response.data.data;
        this.branchID = this.branches[0];
        sessionStorage.setItem('corpBranchID', this.branchID.corpBranchID);
        this.getCorpWalletDetails();
      
          
        });
    },
    onchangeBranch(){
       // this.corpBranchID = this.branchID.corpBranchID;
        sessionStorage.setItem('corpBranchID', this.branchID.corpBranchID);
       
    },
     getCorporateCountry(){
        this.axios
        .post(this.$loggedRole+"/getCorporateCountry", {corporateID:this.$storageData.profile.corporateID}
        )
        .then((response) => {
                this.countryArr = response.data.data;
                this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getCorporateCities();
                
        });
    },
    getCorporateCities(){
         this.axios
        .post(this.$loggedRole+"/getCorporateCities", {corporateID:this.$storageData.profile.corporateID,
           country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                 this.getCorporateBranchByCorpID();
              
                
        });
    },

  getCorpWalletDetails(){
         this.axios
        .post(this.$loggedRole+"/getCorpWalletDetails", {
           corpBranchID: sessionStorage.getItem('corpBranchID')}
        )
        .then((response) => {
                this.walletExpiry = response.data.data;
              
              
                
        });
    },
  

  },
     mounted(){
       
        if(this.$route.params.id){
           sessionStorage.setItem('corpBranchID',this.$route.params.id);
        }else{
           sessionStorage.setItem('corpBranchID', this.$storageData.profile.corpBranchID);
        }

        if(this.$storageData.profile.empTypeID==5 && this.$storageData.login_type==3){ // corp brand login
         
          this.getCorporateCountry();
        }else{
          this.getCorpWalletDetails();
        }

     

  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
     <div class="col-md-1" style="position: absolute;right: 57%;top: 75px;"
        v-if="this.$storageData.profile.empTypeID==5">

                     <label>Select Country </label>
                        <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                          @input="getCorporateCities();" :show-labels="false"></multiselect>
    </div>
    <div class="col-md-2" style="position: absolute;right: 40.5%;top: 75px;" 
         v-if="this.$storageData.profile.empTypeID==5">

                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="getCorporateBranchByCorpID();" :show-labels="false"></multiselect>
         </div>
     <div class="col-md-3" style="position: absolute;right: 15.5%;top: 75px;" 
         v-if="this.$storageData.profile.empTypeID==5">

                     <label>Select Corporate Branch* </label>
                        <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                          @input="onchangeBranch();" :show-labels="false"></multiselect>
      </div>

     <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Wallet Points Added Successfully !
    </b-alert>
  </div>
     
     <div class="col-lg-12" style="margin-top:30px;">
            <div class="card">
                <div class="card-body">
                 
                    <label> Select Excel: </label>
                    <div class="file-upload-form">
                                Choose File
                                <input type="file" class="uploadInput" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                @change="handleFileUpload"  >
                    </div>
                    <p> <a href='#' @click="downloadSample();">Click here</a>
                     to download sample excel for all Employees.</p>
                    <!-- <img src="./../../../assets/logo.png" width="20" height="20"> -->
                    <!-- accept=".xls,.xlsx" -->
                    <p style="font-weight: 500;"> *{{walletExpiry}}* </p>
                    <div v-if="showOtp" class="col-md-6">
                      <label class="form-label"  for="formrow-menuTitle-input">Enter OTP *</label>
                        <input type="text" class="form-control" id="formrow-menuTitle-input" name="menuTitle" 
                        v-model="otp" 
                         >
                    </div>
                      
                  
                  <!-- send otp button -->
                  <div v-else class =" row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                    <button type="button" @click="sendOtp();" class="col-3 btn btn-themeYellow w-md waves-effect waves-light">Send OTP</button>
                  </div>
                  <!-- verify and submit button -->
                   <div  v-if="showSubmitBtn" class ="row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                    <button type="button" @click="submitExcelUsers();" class="btn btn-themeYellow">Verify & Submit</button>
                  </div>

                </div>
               
            </div>
        </div>
      

  

</Layout>
</template>

<style scoped>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


