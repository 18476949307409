import { render, staticRenderFns } from "./walletForm.vue?vue&type=template&id=7119a8a4&scoped=true&lang=en&"
import script from "./walletForm.vue?vue&type=script&lang=js&"
export * from "./walletForm.vue?vue&type=script&lang=js&"
import style0 from "./walletForm.vue?vue&type=style&index=0&id=7119a8a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7119a8a4",
  null
  
)

export default component.exports